<ng-template #templatePrecoOferta let-md>
  <div *ngIf="md.md" class="info-text"><span>{{ tituloOferta }}</span></div>
  <div class="info-price">
    {{ (md.md ? precoProduto : mostrarComparacaoPrecoAntigo(produto.oferta) ? (produto.oferta.preco_antigo) : (produto.oferta.preco_oferta)) | price }}
    <span class="info-price" *ngIf="!produto.possui_unidade_diferente">{{ unidade }}</span>
    <div class="info-price info-price-unidade-medida {{ !md.md ? 'info-price-unidade-medida-sm' : '' }}" *ngIf="possuiUnidadeMedida">
      {{ (md.md ? precoUnidadeMedida : precoUnidadeMedidaSm) | price }}/{{ fracaoUnidadeMedida }}
    </div>
  </div>
  <div class="{{ arrowClass }}"><div class="bullet"></div></div>
</ng-template>

<ng-container *ngIf="produto.disponivel">
  <ng-container *ngIf="!produto.em_oferta">
    <div class="drill-price drill-price-md text-danger" [ngClass]="{'drill-price-unidade-medida': possuiUnidadeMedida}">
      <ng-container *ngTemplateOutlet="templatePrecoOferta; context: { $implicit: {md: true} }"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="produto.em_oferta">
    <div class="drill-price drill-price-sm text-danger" *ngIf="produto.oferta.classe_oferta" [ngClass]="{'drill-price-preco-vip': possuiUnidadeMedida}">
      <ng-container *ngTemplateOutlet="templatePrecoOferta; context: { $implicit: {md: false} }"></ng-container>
    </div>

    <div class="drill-price drill-price-md text-success" [ngSwitch]="produto.oferta.classe_oferta" [ngClass]="{'drill-price-unidade-medida': possuiUnidadeMedida}">
      <ng-template ngSwitchDefault>
        <ng-container *ngTemplateOutlet="templatePrecoOferta; context: { $implicit: {md: true} }"></ng-container>
      </ng-template>

      <ng-template [ngSwitchCase]="'oferta-atacarejo'">
        <div [ngSwitch]="produto.oferta.faixas_precos.length">
          <ng-template [ngSwitchCase]="1">
            <div class="info-text">A cada {{produto.oferta.faixas_precos[0].quantidade}} por</div>
            <div class="info-price oferta-atacarejo" *ngFor="let faixa of produto.oferta.faixas_precos">{{faixa.valor | price}} {{unidade}} </div>
            <div *ngFor="let faixa of produto.oferta.faixas_precos">
              <div class="info-price info-price-unidade-medida" *ngIf="possuiUnidadeMedida">
                {{ faixa.preco_oferta_fracao | price }}/{{ fracaoUnidadeMedida }}
              </div>
            </div>
          </ng-template>
          <ng-template [ngSwitchCase]="2">
            <div class="info-price oferta-atacarejo oferta-atacarejo-for" *ngFor="let faixa of produto.oferta.faixas_precos">{{faixa.quantidade}} por {{faixa.valor | price}} {{unidade}}
              <span *ngIf="possuiUnidadeMedida">{{ faixa.preco_oferta_fracao | price }}/{{ fracaoUnidadeMedida }}</span>
            </div>
          </ng-template>
          <ng-template ngSwitchDefault>
            <div class="info-price oferta-atacarejo oferta-atacarejo-default" *ngFor="let faixa of produto.oferta.faixas_precos">{{faixa.quantidade}} por {{faixa.valor | price}} {{unidade}}
              <span *ngIf="possuiUnidadeMedida">{{ faixa.preco_oferta_fracao | price }}/{{ fracaoUnidadeMedida }}</span>
            </div>
          </ng-template>
        </div>
        <div class="{{ arrowClass }}">
          <div class="bullet"></div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
