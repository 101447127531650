import {
  ApplicationRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ProdutoBuscaService } from '../../produtos/produto-busca/shared/produto-busca.service';

import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { Observable, Subscription } from 'rxjs';
import { Produto } from 'src/app/produtos/shared/produto';
import { CarrinhoService } from '../carrinho/shared/carrinho.service';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import {Oferta} from '../../produtos/shared/ofertas';

@Component({
  selector: 'app-busca-rapida',
  templateUrl: './busca-rapida.component.html',
  styleUrls: ['./busca-rapida.component.css']
})
export class BuscaRapidaComponent implements OnInit, OnDestroy {
  public query: string = null;
  public hidden: boolean = true;

  public busca$: Observable<any>;
  public sugestoes$: Observable<any>;
  public afterAddCarrinho$: Subscription;

  constructor(
    private produtoBuscaService: ProdutoBuscaService,
    private elementRef: ElementRef,
    private applicationRef: ApplicationRef,
    private cdService: CentroDistribuicaoService,
    private carrinhoService: CarrinhoService
  ) {
    this.busca$ = this.produtoBuscaService.queryStream$.pipe(
      filter(termo => termo.length > 3),
      debounceTime(200),
      tap(() => this.produtoBuscaService.visibleSearch = true),
      switchMap((termo) => {
        this.query = termo
        return this.produtoBuscaService.buscaRapidaProdutos(this.cdService.cdSelecionado.id, termo)
      })
    )

    this.sugestoes$ = this.busca$.pipe(
      filter(result => result.data),
      debounceTime(200),
      switchMap((result) => this.produtoBuscaService.buscaSugestao(result.data.termo))
    );

    this.produtoBuscaService.visibilityStream$.subscribe(visible => {
      this.hidden = !visible;
    });
  }

  public close() {
    this.hidden = true;
  }

  ngOnInit(): void {
    this.addObservableProdutoCarrinho();
  }

  ngOnDestroy(): void {
    this.afterAddCarrinho$.unsubscribe();
  }

  private addObservableProdutoCarrinho() {
    this.afterAddCarrinho$ = this.carrinhoService.afterAddCarrinho$.subscribe(
      this.salvarProdutoEncontrado.bind(this)
    );
  }

  private salvarProdutoEncontrado(info) {
    if ('data' in info && !this.hidden) {
      this.produtoBuscaService.salvarProdutoEncontrado(info.data.mercadoria_id);
    }
  }

  getPrecoProduto(produto: Produto) {
    if (this.mostrarPrecoAntigo(produto)) {
      return produto.oferta.preco_antigo;
    } else if (produto.em_oferta) {
      return produto.oferta.preco_oferta;
    }
    return produto.preco;
  }

  getPrecoProdutoUnidadeMedida(produto: Produto) {
    if (this.mostrarPrecoAntigo(produto)) {
      return produto.oferta.preco_antigo_fracao;
    } else if (produto.em_oferta) {
      return produto.oferta.preco_oferta_fracao;
    }
    return produto.unidade_fracao.preco;
  }

  private mostrarPrecoAntigo(produto) {
    return this.eOfertaMaisPorMenos(produto) || this.eOfertaCombo(produto)
  }

  public exibePrecoOriginal(produto: Produto) {
    return (produto.em_oferta && (produto.oferta as Oferta).exibe_preco_original) ||
      (!produto.em_oferta && produto.exibe_preco_original);
  }

  private eOfertaCombo(produto) {
    return produto.em_oferta && produto.oferta.classe_oferta === 'combo-vip'
  }

  private eOfertaMaisPorMenos(produto: Produto) {
    return produto.em_oferta && produto.oferta.quantidade_minima > 1
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const inputBusca =
      target.id === 'inputBuscaRapida' &&
      target.tagName === 'INPUT' &&
      target.value;

    if (inputBusca) {
      this.hidden = false;
      return;
    }

    if (!this.hidden) {
      const clickedInside = this.elementRef.nativeElement.contains(target);
      this.hidden = !clickedInside;
      this.applicationRef.tick();
    }
  }
}
