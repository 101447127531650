import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MensagemAlteracaoCd } from './MensagemAlteracaoCd';
import * as i0 from "@angular/core";
import * as i1 from "angular-2-local-storage";
import * as i2 from "@angular/common/http";
export class AlterarLojaService {
    constructor(lsService, httpClient) {
        this.lsService = lsService;
        this.httpClient = httpClient;
        this._depoisSelecionarLoja$ = new Subject();
        this.depoisSelecionarLoja$ = this._depoisSelecionarLoja$.asObservable();
    }
    getOrigem() {
        return this.lsService.get('loja');
    }
    salvarLojaLocal(loja) {
        this.lsService.set('loja', loja);
        this.lsService.set('cdSelecionado', loja.id);
    }
    alterarLoja(lojaDestino) {
        const lojaOrigem = this.getOrigem();
        const lojaAlterada = lojaOrigem.id !== lojaDestino.id;
        this.salvarLojaLocal(lojaDestino);
        return {
            lojaAlterada,
            lojaOrigem,
            lojaDestino
        };
    }
    salvarLojaSelecionada(lojaDestino, montarCarrinho = true) {
        const infoEmit = this.alterarLoja(lojaDestino);
        this._depoisSelecionarLoja$.next(Object.assign({}, infoEmit, { montarCarrinho }));
    }
    confirmarAlteracaoCd(msg = MensagemAlteracaoCd.MENSAGEM_PADRAO) {
        return confirm(msg);
    }
    verificarPossibilidadeTrocarCarrinhoCd(cdAtualId, novoCdId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdAtualId}/verificar_possibilidade_trocar_carrinho_cd`;
        return this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, { novo_cd_id: novoCdId });
    }
}
AlterarLojaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlterarLojaService_Factory() { return new AlterarLojaService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.HttpClient)); }, token: AlterarLojaService, providedIn: "root" });
