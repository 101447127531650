/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carrinho-adicionar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../carrinho-spinner/carrinho-spinner.component.ngfactory";
import * as i4 from "../../../produtos/produto-detalhe/shared/produto-detalhe.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../carrinho-spinner/carrinho-spinner.component";
import * as i7 from "../shared/carrinho.service";
import * as i8 from "../../../centro-distribuicao/shared/centro-distribuicao.service";
import * as i9 from "../../../shared/services/departamento.service";
import * as i10 from "../../../filiais/shared/filial.service";
import * as i11 from "../../../shared/directives/metrics.directive";
import * as i12 from "angulartics2";
import * as i13 from "../../../shared/pipes/capitalize.pipe";
import * as i14 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i15 from "../../../produtos/produto-aviso/shared/produto-aviso.service";
import * as i16 from "./carrinho-adicionar.component";
import * as i17 from "ngx-bootstrap/modal";
import * as i18 from "../../../tipo-entregas/shared/tipo-entrega.service";
import * as i19 from "../shared/carrinho-anonimo.service";
var styles_CarrinhoAdicionarComponent = [i0.styles];
var RenderType_CarrinhoAdicionarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarrinhoAdicionarComponent, data: {} });
export { RenderType_CarrinhoAdicionarComponent as RenderType_CarrinhoAdicionarComponent };
function View_CarrinhoAdicionarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Produto removido..."]))], null, null); }
function View_CarrinhoAdicionarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Produto adicionado..."]))], null, null); }
function View_CarrinhoAdicionarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "pull-left feedback"], ["style", "clear: both;font-size: 12px"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarrinhoAdicionarComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarrinhoAdicionarComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.quantidade <= 0); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.quantidade > 0) && _co.showAvailableWarn()); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "info-", _co.produtoId, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CarrinhoAdicionarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "wrap-btn-volume"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "show-btn-volume": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-outline btn-volume"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.comprarVolume() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, [" + ", " ", " "])), i1.ɵppd(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 12, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-carrinho-spinner", [], null, [[null, "quantidadeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("quantidadeChange" === en)) {
        var pd_0 = ((_co.quantidade = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CarrinhoSpinnerComponent_0, i3.RenderType_CarrinhoSpinnerComponent)), i1.ɵprd(512, null, i4.ProdutoDetalheService, i4.ProdutoDetalheService, [i5.HttpClient]), i1.ɵdid(13, 114688, null, 0, i6.CarrinhoSpinnerComponent, [i7.CarrinhoService, i8.CentroDistribuicaoService, i4.ProdutoDetalheService, i9.DepartamentoService, i10.FilialService], { produtoId: [0, "produtoId"], quantidade: [1, "quantidade"], tipoProduto: [2, "tipoProduto"], salvarAposAlteracao: [3, "salvarAposAlteracao"], quantidadeMaxima: [4, "quantidadeMaxima"], quantidadeMinima: [5, "quantidadeMinima"] }, { quantidadeChange: "quantidadeChange" }), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["appMetrics", ""], ["class", "btn btn-purchase pull-left"], ["type", "button"]], [[8, "id", 0], [1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.comprar() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i11.MetricsDirective, [i12.Angulartics2], { appMtActionClick: [0, "appMtActionClick"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-shopping-cart hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" COMPRAR "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarrinhoAdicionarComponent_2)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wrap-btn-volume"; var currVal_1 = _ck(_v, 5, 0, (((_co.quantidade > 1) && _co.qtdVolumePrincipal) && _co.enableBtnVolume)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = _co.produtoId; var currVal_5 = _co.quantidade; var currVal_6 = _co.tipoProduto; var currVal_7 = false; var currVal_8 = _co.quantidadeMaximaPermitida; var currVal_9 = 1; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = _co.getActionLabel(); _ck(_v, 17, 0, currVal_12); var currVal_13 = _co.showLoading; _ck(_v, 21, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.qtdVolumePrincipal; var currVal_3 = i1.ɵunv(_v, 7, 1, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.unidadeSigla)); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_10 = i1.ɵinlineInterpolate(1, "btn-", _co.produtoId, ""); var currVal_11 = ((_co.showLoading && _co.showAvailableWarn()) ? "disabled" : null); _ck(_v, 16, 0, currVal_10, currVal_11); }); }
function View_CarrinhoAdicionarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appMetrics", ""], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.solicitarProduto() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i11.MetricsDirective, [i12.Angulartics2], { appMtActionClick: [0, "appMtActionClick"] }, null), (_l()(), i1.ɵted(-1, null, ["Avise-me quando chegar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getActionLabel(); _ck(_v, 1, 0, currVal_0); }, null); }
function View_CarrinhoAdicionarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-xs-12 col-sm-12 col-md-12 em-falta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Produto indispon\u00EDvel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarrinhoAdicionarComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAvailableWarn(); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CarrinhoAdicionarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.CapitalizePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarrinhoAdicionarComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarrinhoAdicionarComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disponivel; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.disponivel; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CarrinhoAdicionarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-carrinho-adicionar", [], null, null, null, View_CarrinhoAdicionarComponent_0, RenderType_CarrinhoAdicionarComponent)), i1.ɵprd(4608, null, i14.CookieService, i14.CookieService, [i2.DOCUMENT]), i1.ɵprd(512, null, i15.ProdutoAvisoService, i15.ProdutoAvisoService, [i5.HttpClient]), i1.ɵprd(512, null, i4.ProdutoDetalheService, i4.ProdutoDetalheService, [i5.HttpClient]), i1.ɵdid(4, 114688, null, 0, i16.CarrinhoAdicionarComponent, [i7.CarrinhoService, i15.ProdutoAvisoService, i8.CentroDistribuicaoService, i10.FilialService, i17.BsModalService, i18.TipoEntregaService, i19.CarrinhoAnonimoService, i9.DepartamentoService, i4.ProdutoDetalheService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var CarrinhoAdicionarComponentNgFactory = i1.ɵccf("app-carrinho-adicionar", i16.CarrinhoAdicionarComponent, View_CarrinhoAdicionarComponent_Host_0, { produtoId: "produtoId", disponivel: "disponivel", tipoProduto: "tipoProduto", quantidade: "quantidade", quantidadeMaxima: "quantidadeMaxima", emOferta: "emOferta", tipoOferta: "tipoOferta", quantidadeMaximaOferta: "quantidadeMaximaOferta", appMtOrigin: "appMtOrigin", qtdVolumePrincipal: "qtdVolumePrincipal", unidadeSigla: "unidadeSigla", enableBtnVolume: "enableBtnVolume" }, {}, []);
export { CarrinhoAdicionarComponentNgFactory as CarrinhoAdicionarComponentNgFactory };
