import { Location } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { environment } from 'src/environments/environment';
import { AppState } from './app.service';
import { CentroDistribuicaoService } from './centro-distribuicao/shared/centro-distribuicao.service';
import { ClienteService } from './clientes/shared/cliente.service';
import { FilialService } from './filiais/shared/filial.service';
import { MinhasListasProductComponent } from './minhas-listas/modals/minhas-listas-product/minhas-listas-product.component';
import { TermosUsoComponent } from './partials/termos-uso/termos-uso.component';
import { SeletorLojaComponent } from './seletor-loja/seletor-loja.component';
import { ConfiguracaoService } from './shared/services/configuracao.service';
import { FidelidadeService } from './shared/services/fidelidade.service';
import { SmartBannerService } from './shared/services/smartbanner.service';
import { TermosUsoService } from './shared/services/termos-uso.service';
import { ViewService } from './shared/services/view.service';
import { UsuarioService } from './televendas/shared/usuario.service';

/**
 * App Component
 */
@Component({
    selector: 'app-root',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./app.component.less'],
    template: `
    <app-offline *ngIf="!viewService.isOnline()"></app-offline>
    <app-navbar>Navbar ...</app-navbar>
    <app-header>Header ...</app-header>
    <app-card-adicionar-carrinho></app-card-adicionar-carrinho>
    <app-busca-rapida></app-busca-rapida>
    <app-tabs *ngIf="!isMobileResolution">Tabs...</app-tabs>
    <app-banners>Banners...</app-banners>
    <app-tabs-bar>Tabs bar...</app-tabs-bar>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!viewService.isInAppMode()">Footer...</app-footer>
  `
})
export class AppComponent implements OnInit {
    public modalRef: BsModalRef;
    public isMobileResolution: boolean = false;
    private readonly urlsExcecoes = ['/clientes/redefinirSenha/', 'televendas', '/institucional/paginas/politica-de-privacidade'];
    private readonly tipoFilialScriptsMetatag = 'metatag';

    constructor(
        public appState: AppState,
        public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        public angulartics2Facebook: Angulartics2Facebook,
        public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private router: Router,
        private modalService: BsModalService,
        private configService: ConfiguracaoService,
        private filialService: FilialService,
        private clienteService: ClienteService,
        private cdService: CentroDistribuicaoService,
        private meta: Meta,
        private smartBannerService: SmartBannerService,
        private location: Location,
        private fidelidadeService: FidelidadeService,
        public viewService: ViewService,
        public firebaseService: FirebaseService,
        private termosUsoService: TermosUsoService,
        private usuarioService: UsuarioService,
        @Inject('Window') private w: Window
    ) {
        angulartics2GoogleAnalytics.startTracking();
        this.validateIsMobileResolution();
        this.router.events.subscribe((event: any) => {
            this.openSelectStore(event);
        });
    }

    private openSelectStore(event) {
        if (
            event instanceof NavigationEnd &&
            event.url.match(/[?|&]selecionarLoja/)
        ) {
            let main_domain = environment.main_domain.replace(
                /(\w+\.)(\w+\.com\.br)/g,
                '$2'
            );
            this.configService.getDomains(main_domain).then((response: any) => {
                let data = response.data[0];
                let filiais = {
                    titulo: data.titulo,
                    dominios: data.child_dominios
                };
                this.modalRef = this.modalService.show(SeletorLojaComponent, {
                    backdrop: 'static',
                    keyboard: false,
                    class: 'modal-loja-selector'
                });
                this.modalRef.content.filiais = filiais;
            });
        }
    }

    private setInfoFilial() {
        const filial = this.filialService.detail;
        this.validaEmManutencao(filial.em_manutencao);
        this.appendHtmlDescricaoMeta(filial.descricao_site);
        this.appendHtmlFacebookDomainVerification(filial.facebook_domain_verification);
        this.appendCssTagsLink();
        this.addFacebookPixel(filial.facebook_pixel);
        this.addGa(filial.ga);
        this.addGa4(filial.ga4);
        this.addRdStation(filial.rd_station);
        this.addGoogleAnalytics(filial.google_analytics);
        this.addScripts();
        this.startSmartBanner();
        this.fidelidadeService.buscarConfiguracao().then(() => {
            this.defineTelaInicial(filial);
        });
    }

    public ngOnInit() {
        this.cdService.countCds();

        window.addEventListener('addProductToList', e => this.addProductToList(e));

        this.setInfoFilial();
        this.exibirSelecaoCd();
        this.afterLoginInAppMode();

        this.firebaseService.initialize();

        if (this.clienteService.logged()) {
            this.verificarAceiteTermosUso();
        }
    }

    private afterLoginInAppMode() {
        const subscription = this.cdService.visibilidadeCd$.subscribe(() => {
            if (this.viewService.isInAppMode() && this.clienteService.logged()) {
                this.clienteService.getDescricaoCliente();
                this.clienteService.afterLogin$.next();
            }
            subscription.unsubscribe();
        });
    }

    private exibirSelecaoCd() {
        const matches = RegExp(this.urlsExcecoes.join('|')).exec(window.location.href);

        if (!matches && !this.filialService.detail.em_manutencao)
            this.cdService.exibirSelecaoCdAoIniciar();
    }

    private addFacebookPixel(pixel) {
        if (pixel) {
            this.appendFacebookPixelScript();
        }
    }

    private addGa(ga) {
        if (ga) {
            this.appendGoogleAnalyticsScript();
        }
    }

    private addGa4(ga4) {
        if (ga4) {
            let elem = $('<script></script>').attr('src', 'assets/js/ga4.js');
            $('head').prepend(elem);

            elem = $('<script></script>').attr('src', `https://www.googletagmanager.com/gtag/js?id=${ga4}`);
            $('head').prepend(elem);
        }
    }


    private addRdStation(rdStation) {
        if (rdStation) {
            this._appendScript(rdStation);
        }
    }

    private addGoogleAnalytics(googleAnalytics) {
        if (googleAnalytics) {
            this.appendGoogleRemarketing(googleAnalytics);
        }
    }

    private addScripts() {
        this.filialService.buscarScripts().subscribe(req => {
            const scripts = req.data;
            if (scripts.length > 0) {
                scripts.forEach(script => {
                    script.tag === this.tipoFilialScriptsMetatag ?
                        $('head').append(script.txt_script as HTMLMetaElement) :
                        this._appendScript(script.txt_script);
                });
            }
        });
    }

    private validaEmManutencao(emManutencao: boolean) {
        if (emManutencao) {
            this.router.navigate(['/manutencao']);
        }
    }

    private appendFacebookPixelScript() {
        let elem = $('<script></script>').attr(
            'src',
            'assets/js/facebook-pixel.js'
        );
        $('body').append(elem);
    }

    private appendGoogleAnalyticsScript() {
        let elem = $('<script></script>').attr('src', 'assets/js/ga.js');
        $('head').prepend(elem);
    }

    private _appendScript(script: any, appendTo = 'body') {
        const elem = $(script);

        if (elem.is('script')) {
            $(appendTo).prepend(elem);
        }
    }

    private appendHtmlDescricaoMeta(descricaoSite: string) {
        this.meta.addTag({
            name: 'description',
            content: descricaoSite
        });
    }

    private appendHtmlFacebookDomainVerification(facebook_domain_verification: string) {
        this.meta.addTag({
            name: 'facebook-domain-verification',
            content: facebook_domain_verification
        });
    }

    private appendCssTagsLink() {
        // TODO: Remover jQuery
        let elem = $('<link></link>')
            .attr('rel', 'stylesheet')
            .attr(
                'href',
                environment.wsUrl +
                '/filiais/formatacao/css-tags/' +
                environment.main_domain +
                '/' +
                environment.filialId +
                '.css?' +
                new Date().getTime()
            );
        $('head').append(elem);
    }

    private appendGoogleRemarketing(remarketingTag: any) {
        const appendTo = remarketingTag.search('gtag') !== -1 ? 'head' : 'body';
        this._appendScript(remarketingTag, appendTo);
    }

    private startSmartBanner() {
        setTimeout(() => {
            this.smartBannerService.open();
        }, 2000);
    }

    private addProductToList(e: any) {
        if (this.clienteService.logged()) {
            let modal = this.modalService.show(MinhasListasProductComponent, {
                keyboard: true,
                class: 'custom-modal',
                ignoreBackdropClick: false
            });
            e.detail.quantidade = 1;
            modal.content.product = e.detail;
        }
    }

    private validateIsMobileResolution() {
        this.isMobileResolution = this.w.innerWidth < 992;
    }

    private defineTelaInicial(filial) {
        const vorticeConfig = this.fidelidadeService.vorticeParams();
        const currentRoute = this.location.path();
        if (vorticeConfig && vorticeConfig.ativo && filial.filial_sem_ecommerce && (currentRoute === '/' || currentRoute === '')) {
            this.fidelidadeService.getCampaigns().subscribe((res: any) => {
                if (res && res.data && res.data.length) {
                    this.router.navigate(['/sorteios']);
                }
            });
        }
    }

    private verificarAceiteTermosUso() {
        const isTelevendas = !!this.usuarioService.getUser();
        if (!isTelevendas) {
            this.termosUsoService.getPossuiTermoUsoPendente(environment.filialId).then(() => {
                if (this.termosUsoService.aprovacaoPendente) {
                    this.modalService.show(TermosUsoComponent, {
                        backdrop: 'static',
                        keyboard: false,
                    });
                }
            });
        }
    }
}
