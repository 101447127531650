import { Component, OnInit, Input } from '@angular/core';
import { Produto } from 'src/app/produtos/shared/produto';
import {TipoOfertaEnum} from '../../produtos/shared/tipo_oferta.enum';
import {of} from 'rxjs';
import {Oferta} from '../../produtos/shared/ofertas';

@Component({
  selector: 'app-tag-preco',
  templateUrl: './tag-preco.component.html',
  styleUrls: ['./tag-preco.component.less']
})
export class TagPrecoComponent implements OnInit {

  @Input() produto: Produto = {} as Produto;
  @Input() arrowDirection: 'left'|'right' = 'right';

  get arrowClass() {
    return this.arrowDirection ? `arrow-${this.arrowDirection}` : 'arrow-right';
  }

  constructor() { }

  ngOnInit() { }

  get unidade() {
    return !this.produto.possui_unidade_diferente
        ? "un." : "";
  }

  get precoUnidadeMedida() {
    if (this.produto.oferta) {
      return this.produto.oferta.preco_oferta_fracao;
    }
    return this.produto.unidade_fracao.preco;
  }

  get precoUnidadeMedidaSm() {
    if (this.mostrarComparacaoPrecoAntigo(this.produto.oferta)) {
      return this.produto.oferta.preco_antigo_fracao;
    } else if (this.mostrarComparacaoPrecoOferta(this.produto.oferta) &&
        this.produto.oferta.tipo_oferta_id === TipoOfertaEnum.OFERTA_ATACAREJO) {
      return this.produto.oferta.preco_oferta_fracao;
    }
    return this.produto.oferta.preco_antigo_fracao;
  }

  get precoProduto() {
    if (this.produto.em_oferta) {
      if (this.produto.oferta.tipo_oferta_id === TipoOfertaEnum.LEVE_E_PAGUE) {
        return (this.produto.oferta.preco_oferta * this.produto.oferta.quantidade_pagar) /
            this.produto.oferta.quantidade_minima;
      }
      return this.produto.oferta.preco_oferta;
    }
    return this.produto.preco;
  }

  get possuiUnidadeMedida() {
    return this.produto.unidade_fracao && this.produto.unidade_fracao.preco && !this.exibePrecoOriginal;
  }

  get fracaoUnidadeMedida() {
    return this.produto.unidade_fracao.fracao + this.produto.unidade_fracao.sigla.toLowerCase();
  }

  get tituloOferta() {
    if (this.produto.em_oferta) {
      switch (this.produto.oferta.tipo_oferta_id) {
        case (TipoOfertaEnum.COMBO_DE_PRODUTOS):
          return 'No combo';
        case TipoOfertaEnum.PRODUTO_COM_DESCONTO: case TipoOfertaEnum.CATEGORIA_DE_PRODUTOS:
          return this.produto.oferta.quantidade_minima > 1 ? `A partir de ${this.produto.oferta.quantidade_minima} por` : 'Por apenas';
        case (TipoOfertaEnum.LEVE_E_PAGUE):
          return `Leve ${this.produto.oferta.quantidade_minima} pague ${this.produto.oferta.quantidade_pagar} `;
        default:
          return 'Por apenas';
      }
    }
    return 'Por apenas';
  }

  mostrarComparacaoPrecoOferta(oferta) {
    if (!oferta) {
      return false;
    }
    return oferta.classe_oferta === 'leve-e-pague' || oferta.classe_oferta === 'oferta-atacarejo';
  }

  mostrarComparacaoPrecoAntigo(oferta) {
    const excetoOfertas = !this.mostrarComparacaoPrecoOferta(oferta);
    return excetoOfertas;
  }

  get exibePrecoOriginal() {
    return  (this.produto.em_oferta && (this.produto.oferta as Oferta).exibe_preco_original) ||
        (!this.produto.em_oferta && this.produto.exibe_preco_original);
  }
}
