import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cd } from './cd';
import { MensagemAlteracaoCd } from './MensagemAlteracaoCd';

@Injectable({
  providedIn: 'root'
})
export class AlterarLojaService {
  private _depoisSelecionarLoja$: Subject<any> = new Subject();
  public depoisSelecionarLoja$: Observable<
    any
  > = this._depoisSelecionarLoja$.asObservable();

  constructor(private lsService: LocalStorageService,
              private httpClient: HttpClient) {}

  public getOrigem(): Cd {
    return this.lsService.get('loja');
  }

  private salvarLojaLocal(loja: Cd) {
    this.lsService.set('loja', loja);
    this.lsService.set('cdSelecionado', loja.id);
  }

  public alterarLoja(lojaDestino: Cd) {
    const lojaOrigem = this.getOrigem();
    const lojaAlterada = lojaOrigem.id !== lojaDestino.id;
    this.salvarLojaLocal(lojaDestino);

    return {
      lojaAlterada,
      lojaOrigem,
      lojaDestino
    };
  }

  public salvarLojaSelecionada(lojaDestino: Cd, montarCarrinho: boolean = true) {
    const infoEmit = this.alterarLoja(lojaDestino);
    this._depoisSelecionarLoja$.next({ ...infoEmit, montarCarrinho });
  }

  public confirmarAlteracaoCd(msg = MensagemAlteracaoCd.MENSAGEM_PADRAO) {
    return confirm(msg);
  }

  verificarPossibilidadeTrocarCarrinhoCd(cdAtualId: number, novoCdId: number) {
    const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdAtualId}/verificar_possibilidade_trocar_carrinho_cd`;
    return this.httpClient.post<any>(`${environment.apiUrl}/loja/${URI}`, { novo_cd_id: novoCdId });
  }
}
